import React, { PropsWithChildren } from "react";
import { Stack, IStackTokens, mergeStyleSets } from "@fluentui/react";

import BodyProvider from "./BodyProvider";
import Menu from "./Menu";

interface BodyProps {
    isIndex?: boolean;
}

const Body: React.FunctionComponent<PropsWithChildren<BodyProps>> = (props: PropsWithChildren<BodyProps>) => {
    const { children, isIndex = false } = props;

    const mainStyle = mergeStyleSets({
        mainRoot: {
            display: `flex`,
            flexFlow: `column`,
            height: `100%`,
            selectors: {
                blockquote: {
                    backgroundColor: `lightgray`,
                    padding: `10px`,
                },
            },
        },
        contentRoot: {
            width: "100%",
            height: `calc(100vh - 80px)`,
            overflow: (isIndex ? `none` : `auto`) as any,
        },
        bodyRoot: {
            height: `calc(100vh - 80px)`,
        },
    });

    const bodyTokens: IStackTokens = {
        childrenGap: `10`,
    };

    return (
        <BodyProvider>
            <Stack horizontal className={mainStyle.bodyRoot} tokens={bodyTokens}>
                {!isIndex && <Menu />}
                <Stack.Item className={mainStyle.contentRoot}>
                    <main className={mainStyle.mainRoot}>{children}</main>
                </Stack.Item>
            </Stack>
        </BodyProvider>
    );
};

export default Body;
