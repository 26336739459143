import React, { PropsWithChildren } from "react";
import BodyContext from "./BodyContext";

const mobileWidth = 600;

class BodyProvider extends React.Component<PropsWithChildren<Record<string, unknown>>> {
    private menuWidthConst = {
        collapse: 36,
        expand: 200,
        border: 20,
    };

    public constructor(props: PropsWithChildren<Record<string, unknown>>) {
        super(props);
        let menuCollapsed = false;
        if (typeof window !== `undefined`) menuCollapsed = this.isMobile;
        this.state = { menuCollapsed };
        this.recalcMenu = this.recalcMenu.bind(this);
    }

    public toggleMenu(): void {
        const menuCollapsed = !this.menuStatus;
        this.storeState(menuCollapsed);
    }

    public collapseMenu(): void {
        this.storeState(true);
    }

    public expandMenu(): void {
        this.storeState(false);
    }

    public get menuStatus(): boolean {
        return (this.state as any).menuCollapsed as boolean;
    }

    public get menuWidth(): number {
        return this.isMobile ? this.menuWidthConst.collapse : this.menuWidthInternal;
    }

    public get menuWidthMobile(): number {
        if (typeof window === `undefined`) return this.menuWidthConst.collapse;
        return this.menuStatus ? this.menuWidthConst.collapse : this.menuWidthConst.expand;
    }

    public get menuWidthInternal(): number {
        return this.menuStatus ? this.menuWidthConst.collapse : this.menuWidthConst.expand;
    }

    public get menuWidthExternal(): number {
        return this.menuWidth + this.menuWidthConst.border;
    }

    public recalcMenu(): void {
        this.storeState(this.isMobile);
    }

    public componentDidMount(): void {
        window.addEventListener(`resize`, this.recalcMenu);
    }

    public componentWillUnmount(): void {
        window.removeEventListener(`resize`, this.recalcMenu);
    }

    public get isMobile(): boolean {
        return typeof window !== `undefined` ? window.innerWidth < mobileWidth : false;
    }

    public render(): JSX.Element {
        const { children } = this.props;
        const { menuCollapsed } = this.state as any;
        return (
            <BodyContext.Provider
                value={{
                    menuCollapsed,
                    menuWidth: this.menuWidth,
                    menuWidthMobile: this.menuWidthMobile,
                    menuWidthInternal: this.menuWidthInternal,
                    menuWidthExternal: this.menuWidthExternal,
                    toggleMenu: () => this.toggleMenu(),
                    collapseMenu: () => this.collapseMenu(),
                    expandMenu: () => this.expandMenu(),
                }}
            >
                {children}
            </BodyContext.Provider>
        );
    }

    private storeState(menuCollapsed: boolean): void {
        this.setState({ menuCollapsed });
    }
}

export default BodyProvider;
