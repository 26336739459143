import { useIsAuthenticated } from "@azure/msal-react";
import React from "react";

import MenuBase, { MenuProps } from "../base/Menu.base";

const menuLinks = [
    {
        name: `Main`,
        url: `/main/`,
        key: `/main/`,
        icon: `Home`,
        isExpanded: true,
        links: [],
    },
    {
        name: `Secure Embed`,
        isExpanded: true,
        links: [
            {
                name: `Intro`,
                collapseName: `Secure Embed`,
                url: `/secure/`,
                key: `/secure/`,
                icon: `Lightbulb`,
            },
            {
                name: `Example`,
                url: `/secure-example/`,
                key: `/secure-example/`,
                icon: `PowerBILogo16`,
                secure: true,
            },
        ],
    },
    {
        name: `Embedded analytics`,
        isExpanded: true,
        links: [
            {
                name: `Intro`,
                collapseName: `Embedded analytics`,
                url: `/embedded/`,
                key: `/embedded/`,
                icon: `Lightbulb`,
            },
            {
                name: `Example`,
                url: `/embedded-example/`,
                key: `/embedded-example/`,
                icon: `PowerBILogo16`,
                secure: true,
            },
            {
                name: `Interaction`,
                url: `/embedded-interaction/`,
                key: `/embedded-interaction/`,
                icon: `PowerBILogo16`,
                secure: true,
            },
        ],
    },
];

const Menu: React.FunctionComponent<MenuProps> = (props: MenuProps) => {
    const { path } = props;
    const isAuthenticated = useIsAuthenticated();

    const menuItems = menuLinks.map((menuLink) => {
        const links = menuLink.links.filter((item) => !item.secure || isAuthenticated);
        return {
            name: menuLink.name,
            url: menuLink.url,
            key: menuLink.key,
            icon: links.length === 0 ? menuLink.icon : undefined,
            isExpanded: links.length === 0 ? false : menuLink.isExpanded,
            links: links.length === 0 ? undefined : links,
        };
    });
    const menuCollapsedItems = menuLinks.reduce((prevLinks, currLink) => {
        if (currLink.url)
            prevLinks.push({
                name: currLink.name,
                url: currLink.url,
                key: currLink.key,
                icon: currLink.icon,
            });
        return prevLinks.concat(
            currLink.links
                .filter((item) => !item.secure || isAuthenticated)
                .map((cLink) => {
                    return {
                        name: cLink.collapseName || cLink.name,
                        url: cLink.url,
                        key: cLink.key,
                        icon: cLink.icon,
                    };
                })
        );
    }, [] as any);
    return (
        <MenuBase
            path={path}
            groups={[
                {
                    links: menuItems,
                },
            ]}
            groupsCollapse={[
                {
                    links: menuCollapsedItems,
                },
            ]}
        />
    );
};

export default Menu;
