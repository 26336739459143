import React, { PropsWithChildren } from "react";
import { Link } from "gatsby";
import { classNamesFunction, ITheme, Link as OfficeLink } from "@fluentui/react";
import { IHTMLSlot, IComponentStyles } from "@fluentui/foundation";

const LOCAL_URL = /^\/(?!\/)/;

interface LinkSlots {
    linkRoot?: IHTMLSlot;
}
export declare type LinkStyles = IComponentStyles<LinkSlots>;

const getClassNames = classNamesFunction<ITheme, LinkStyles>();

interface LinkBaseProps {
    styles?: LinkStyles;
    className?: string;
    theme: ITheme;
    href: string;
}

const LinkBase: React.FunctionComponent<LinkBaseProps> = (props: PropsWithChildren<LinkBaseProps>) => {
    const { styles, className, theme, children, href } = props;
    const classNames = getClassNames(styles, theme);

    const localLink = (
        <Link to={href} className={className || classNames.linkRoot}>
            {children}
        </Link>
    );
    const externalLink = (
        <OfficeLink href={href} className={className || classNames.linkRoot} target="_blank" rel="noopener noreferrer">
            {children}
        </OfficeLink>
    );
    return LOCAL_URL.test(href) ? localLink : externalLink;
};

export default LinkBase;
