import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { mergeStyles, fontFace, IFontWeight } from "@fluentui/react";

export interface Font {
    info: {
        fontFamily: string;
        url: string;
    };
}

export interface FontsGraphQL {
    files: {
        fonts: Font[];
    };
}

const GlobalStyle: React.FunctionComponent<Record<string, unknown>> = () => {
    const { files }: FontsGraphQL = useStaticQuery(graphql`
        query {
            files: allFile(filter: { relativePath: { glob: "**/*.woff" } }) {
                fonts: edges {
                    info: node {
                        url: publicURL
                        fontFamily: name
                    }
                }
            }
        }
    `);

    // Register Fonts
    files.fonts.forEach((font: Font) => {
        const fontInfo = font.info.fontFamily.split("-");
        const fontFamily = fontInfo[0];
        const fontStyle: "normal" | "italic" | "oblique" = (
            fontInfo.length > 1 ? fontInfo[1].toLowerCase() : "normal"
        ) as "normal" | "italic" | "oblique";
        const fontWeight: IFontWeight = (fontInfo.length > 2 ? fontInfo[2].toLowerCase() : "normal") as IFontWeight;
        fontFace({
            fontFamily: `${fontFamily}`,
            src: `url(${font.info.url}) format('woff')`,
            fontStyle,
            fontWeight,
        });
    });

    // Global styles
    mergeStyles({
        selectors: {
            ":global(body, html, #___gatsby)": {
                height: "100%",
                margin: "0 auto",
            },
            ":global(body)": {
                fontFamily: "Montserrat, Tahoma, Geneva, sans-serif",
            },
            ":global(h1, h2, h3, h4, h5, h6)": {
                fontFamily: "Convection, Verdana, sans-serif",
            },
        },
    });

    return null;
};

export default GlobalStyle;
