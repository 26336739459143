import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import packageJson from "../../package.json";

interface SEOProps {
    description?: string;
    lang?: string;
    meta?: any;
    title: string;
}

interface SEOGraphQL {
    site: {
        siteMetadata: {
            title: string;
            description: string;
            author: string;
        };
    };
}

const SEO: React.FunctionComponent<SEOProps> = (props: SEOProps) => {
    const { description = ``, lang = `en`, meta = [], title } = props;
    const { site }: SEOGraphQL = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
    `);

    const metaDescription = description || site.siteMetadata.description;
    const version = packageJson.version;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={(
                [
                    {
                        name: `description`,
                        content: metaDescription,
                    },
                    {
                        name: `version`,
                        content: version,
                    },
                    {
                        property: `og:title`,
                        content: title,
                    },
                    {
                        property: `og:description`,
                        content: metaDescription,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        name: `twitter:card`,
                        content: `summary`,
                    },
                    {
                        name: `twitter:creator`,
                        content: site.siteMetadata.author,
                    },
                    {
                        name: `twitter:title`,
                        content: title,
                    },
                    {
                        name: `twitter:description`,
                        content: metaDescription,
                    },
                ] as any[]
            ).concat(meta || [])}
        />
    );
};

export default SEO;
