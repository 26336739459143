import React, { PropsWithChildren } from "react";
import { mergeStyleSets, useTheme } from "@fluentui/react";

import LinkBase from "./base/Link.base";

interface UrlProps {
    href?: string;
}

const LinkPrimaryBackground: React.FunctionComponent<UrlProps> = (props: PropsWithChildren<UrlProps>) => {
    const { children, href = `` } = props;
    const theme = useTheme();

    const linkStyles = mergeStyleSets({
        linkRoot: {
            color: theme.palette.themeLighterAlt,
            textDecoration: `none`,
            selectors: {
                ":hover": {
                    color: theme.palette.neutralTertiary,
                    textDecoration: "none",
                },
            },
        },
    });

    return (
        <LinkBase theme={theme} className={linkStyles.linkRoot} href={href}>
            {children}
        </LinkBase>
    );
};

export default LinkPrimaryBackground;
