import React from "react";
import { mergeStyleSets } from "@fluentui/react";

interface LogoProps {
    height?: number;
    width?: number;
}

const Logo: React.FunctionComponent<LogoProps> = (props: LogoProps) => {
    const { height, width } = props;

    const svgStyle = mergeStyleSets({
        svgRoot: {
            height: height ? `${height}px` : `100%`,
            display: `block`,
            maxHeight: `100px`,
            width: `auto`,
            margin: `0 auto`,
            lineHeight: `0`,
            alignSelf: `center`,
            selectors: {
                "@media(max-width: 430px)": {
                    width: width ? `${width}px` : `90%`,
                    height: `unset`,
                },
            },
        },
    });

    return (
        <svg
            viewBox="0 0 341 80"
            className={svgStyle.svgRoot}
            version="1.1"
            preserveAspectRatio="xMidYMid meet"
            aria-label="DataScenarios logo"
            height={height ? `${height}px` : undefined}
            width={width ? `${width}px` : undefined}
        >
            <title>{"DataScenarios logo"}</title>
            <path fill="#7a7a78" d="M31.762 27.329l10.271-8.559 0.697 0.914-10.191 8.43z"></path>
            <path fill="#7a7a78" d="M11.011 31.803l13.192-10.799 0.769 0.775-13.32 10.834z"></path>
            <path fill="#7a7a78" d="M31.587 27.295l-6.883-5.605 0.83-0.783 6.955 5.629z"></path>
            <path
                fill="#7a7a78"
                d="M26.383 21.258c0 0.935-0.758 1.693-1.693 1.693v0c-0.935 0-1.693-0.758-1.693-1.693v0c0 0 0 0 0 0 0-0.935 0.758-1.693 1.693-1.693v0c0.935 0 1.693 0.758 1.693 1.693 0 0 0 0 0 0v0z"
            ></path>
            <path
                fill="#7a7a78"
                d="M44.822 18.347c0 0.935-0.758 1.693-1.693 1.693v0c-0.935 0-1.693-0.758-1.693-1.693v0c0-0.935 0.758-1.693 1.693-1.693v0c0.935 0 1.693 0.758 1.693 1.693v0z"
            ></path>
            <path
                fill="#7a7a78"
                d="M34.144 26.964c0 0 0 0 0 0 0 0.935-0.758 1.693-1.693 1.693v0c-0.935 0-1.693-0.758-1.693-1.693 0 0 0 0 0 0v0c0-0.935 0.758-1.693 1.693-1.693v0c0.935 0 1.693 0.758 1.693 1.693v0z"
            ></path>
            <path
                fill="#7a7a78"
                d="M13.582 31.434c0 0.935-0.758 1.693-1.693 1.693v0c-0.935 0-1.693-0.758-1.693-1.693v0c0 0 0 0 0 0 0-0.935 0.758-1.693 1.693-1.693v0c0.935 0 1.693 0.758 1.693 1.693 0 0 0 0 0 0v0z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeWidth="1.6697"
                d="M8.019 44.392c0 0.854-0.692 1.546-1.546 1.546v0c-0.854 0-1.546-0.692-1.546-1.546v0c0-0.854 0.692-1.546 1.546-1.546v0c0.854 0 1.546 0.692 1.546 1.546v0z"
            ></path>
            <path fill="#7a7a78" d="M7.27 3.987l-0.006 40.178-1.308-0.011 0.012-40.165 0.672-0.777z"></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeWidth="1.9048"
                d="M9.898 7.017l-3.209-3.682"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeWidth="1.9048"
                d="M6.551 3.335l-3.209 3.682"
            ></path>
            <path fill="#7a7a78" d="M47.215 44.986l-40.178-0.006 0.011-1.308 40.165 0.012 0.777 0.672z"></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeWidth="1.9048"
                d="M44.185 47.614l3.682-3.209"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeWidth="1.9048"
                d="M47.867 44.267l-3.682-3.209"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M16.154 72.034q-0.564 0.242-1.025 0.454-0.451 0.212-1.189 0.444-0.625 0.192-1.364 0.323-0.728 0.141-1.61 0.141-1.661 0-3.024-0.454-1.353-0.464-2.358-1.442-0.984-0.958-1.538-2.43-0.554-1.482-0.554-3.438 0-1.855 0.533-3.317t1.538-2.47q0.974-0.978 2.348-1.492 1.384-0.514 3.065-0.514 1.23 0 2.45 0.292 1.23 0.292 2.727 1.028v2.369h-0.154q-1.261-1.038-2.501-1.512t-2.655-0.474q-1.158 0-2.091 0.373-0.923 0.363-1.651 1.139-0.707 0.756-1.107 1.915-0.39 1.149-0.39 2.661 0 1.583 0.431 2.722 0.441 1.139 1.128 1.855 0.718 0.746 1.671 1.109 0.964 0.353 2.030 0.353 1.466 0 2.747-0.494t2.399-1.482h0.144z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M30.261 67.497q0 2.752-1.435 4.345t-3.844 1.593q-2.43 0-3.865-1.593-1.425-1.593-1.425-4.345t1.425-4.345q1.435-1.603 3.865-1.603 2.409 0 3.844 1.603 1.435 1.593 1.435 4.345zM28.272 67.497q0-2.188-0.871-3.246-0.871-1.069-2.419-1.069-1.569 0-2.44 1.069-0.861 1.059-0.861 3.246 0 2.117 0.871 3.216 0.871 1.089 2.43 1.089 1.538 0 2.409-1.079 0.882-1.089 0.882-3.226z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M44.439 73.123h-1.927v-6.412q0-0.776-0.092-1.452-0.092-0.686-0.338-1.069-0.256-0.423-0.738-0.625-0.482-0.212-1.251-0.212-0.789 0-1.651 0.383t-1.651 0.978v8.408h-1.927v-11.261h1.927v1.25q0.902-0.736 1.866-1.149t1.979-0.413q1.856 0 2.83 1.099t0.974 3.166z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M59.336 73.123h-1.927v-6.412q0-0.776-0.092-1.452-0.092-0.686-0.338-1.069-0.256-0.423-0.738-0.625-0.482-0.212-1.251-0.212-0.789 0-1.651 0.383t-1.651 0.978v8.408h-1.927v-11.261h1.927v1.25q0.902-0.736 1.866-1.149t1.979-0.413q1.856 0 2.83 1.099t0.974 3.166z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M74.242 67.689h-8.437q0 1.038 0.318 1.815 0.318 0.766 0.871 1.26 0.533 0.484 1.261 0.726 0.738 0.242 1.62 0.242 1.169 0 2.348-0.454 1.189-0.464 1.692-0.907h0.102v2.067q-0.974 0.403-1.989 0.675t-2.132 0.272q-2.85 0-4.449-1.512-1.599-1.522-1.599-4.315 0-2.762 1.528-4.385 1.538-1.623 4.039-1.623 2.317 0 3.568 1.331 1.261 1.331 1.261 3.781zM72.366 66.237q-0.010-1.492-0.769-2.309-0.748-0.817-2.286-0.817-1.548 0-2.471 0.897-0.912 0.897-1.035 2.228z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M87.241 72.417q-0.964 0.454-1.835 0.706-0.861 0.252-1.835 0.252-1.24 0-2.276-0.353-1.035-0.363-1.774-1.089-0.748-0.726-1.158-1.835t-0.41-2.591q0-2.762 1.538-4.335 1.548-1.573 4.080-1.573 0.984 0 1.927 0.272 0.953 0.272 1.743 0.665v2.107h-0.102q-0.882-0.675-1.825-1.038-0.933-0.363-1.825-0.363-1.64 0-2.594 1.089-0.943 1.079-0.943 3.176 0 2.036 0.923 3.135 0.933 1.089 2.614 1.089 0.584 0 1.189-0.151t1.087-0.393q0.42-0.212 0.789-0.444 0.369-0.242 0.584-0.413h0.102z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M97.278 73.022q-0.543 0.141-1.189 0.232-0.636 0.091-1.138 0.091-1.753 0-2.665-0.928t-0.912-2.974v-5.988h-1.302v-1.593h1.302v-3.236h1.927v3.236h3.978v1.593h-3.978v5.131q0 0.887 0.041 1.391 0.041 0.494 0.287 0.927 0.226 0.403 0.615 0.595 0.4 0.181 1.21 0.181 0.472 0 0.984-0.131 0.513-0.141 0.738-0.232h0.102z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M103.296 59.977h-2.173v-1.966h2.173zM103.173 73.123h-1.927v-11.261h1.927z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M118.192 73.123h-1.927v-6.412q0-0.776-0.092-1.452-0.092-0.686-0.338-1.069-0.256-0.423-0.738-0.625-0.482-0.212-1.251-0.212-0.789 0-1.651 0.383t-1.651 0.978v8.408h-1.927v-11.261h1.927v1.25q0.902-0.736 1.866-1.149t1.979-0.413q1.856 0 2.83 1.099t0.974 3.166z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M132.801 71.843q0 2.863-1.323 4.204t-4.070 1.341q-0.912 0-1.784-0.131-0.861-0.121-1.702-0.353v-1.936h0.102q0.472 0.181 1.497 0.444 1.025 0.272 2.050 0.272 0.984 0 1.63-0.232t1.005-0.645q0.359-0.393 0.513-0.948t0.154-1.24v-1.028q-0.871 0.686-1.671 1.028-0.789 0.333-2.020 0.333-2.050 0-3.26-1.452-1.199-1.462-1.199-4.113 0-1.452 0.41-2.5 0.42-1.059 1.138-1.825 0.666-0.716 1.62-1.109 0.953-0.403 1.897-0.403 0.994 0 1.661 0.202 0.677 0.192 1.425 0.595l0.123-0.484h1.804zM130.874 70.028v-6.14q-0.769-0.343-1.435-0.484-0.656-0.151-1.312-0.151-1.589 0-2.501 1.048t-0.912 3.045q0 1.895 0.677 2.873t2.245 0.978q0.841 0 1.681-0.313 0.851-0.323 1.558-0.857z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M158.35 73.123h-1.927v-1.18q-0.83 0.706-1.733 1.099t-1.958 0.393q-2.050 0-3.26-1.552-1.199-1.552-1.199-4.305 0-1.432 0.41-2.551 0.42-1.119 1.128-1.905 0.697-0.766 1.62-1.169 0.933-0.403 1.927-0.403 0.902 0 1.599 0.192 0.697 0.181 1.466 0.575v-4.879h1.927zM156.423 70.35v-6.462q-0.779-0.343-1.394-0.474t-1.343-0.131q-1.62 0-2.522 1.109t-0.902 3.145q0 2.006 0.697 3.055 0.697 1.038 2.235 1.038 0.82 0 1.661-0.353 0.841-0.363 1.569-0.927z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M172.651 73.123h-1.917v-1.2q-0.256 0.171-0.697 0.484-0.431 0.302-0.841 0.484-0.482 0.232-1.107 0.383-0.625 0.161-1.466 0.161-1.548 0-2.624-1.008t-1.076-2.571q0-1.28 0.554-2.067 0.564-0.796 1.599-1.25 1.046-0.454 2.512-0.615t3.147-0.242v-0.292q0-0.645-0.236-1.069-0.226-0.423-0.656-0.665-0.41-0.232-0.984-0.313t-1.199-0.081q-0.759 0-1.692 0.202-0.933 0.192-1.927 0.565h-0.102v-1.926q0.564-0.151 1.63-0.333t2.102-0.181q1.21 0 2.102 0.202 0.902 0.192 1.558 0.665 0.646 0.464 0.984 1.2t0.338 1.825zM170.734 70.35v-3.135q-0.882 0.050-2.081 0.151-1.189 0.101-1.886 0.292-0.83 0.232-1.343 0.726-0.513 0.484-0.513 1.341 0 0.968 0.595 1.462 0.595 0.484 1.815 0.484 1.015 0 1.856-0.383 0.841-0.393 1.558-0.938z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M183.939 73.022q-0.543 0.141-1.189 0.232-0.636 0.091-1.138 0.091-1.753 0-2.665-0.928t-0.912-2.974v-5.988h-1.302v-1.593h1.302v-3.236h1.927v3.236h3.978v1.593h-3.978v5.131q0 0.887 0.041 1.391 0.041 0.494 0.287 0.927 0.226 0.403 0.615 0.595 0.4 0.181 1.21 0.181 0.472 0 0.984-0.131 0.513-0.141 0.738-0.232h0.102z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M196.784 73.123h-1.917v-1.2q-0.256 0.171-0.697 0.484-0.431 0.302-0.841 0.484-0.482 0.232-1.107 0.383-0.625 0.161-1.466 0.161-1.548 0-2.624-1.008t-1.076-2.571q0-1.28 0.554-2.067 0.564-0.796 1.599-1.25 1.046-0.454 2.512-0.615t3.147-0.242v-0.292q0-0.645-0.236-1.069-0.226-0.423-0.656-0.665-0.41-0.232-0.984-0.313t-1.199-0.081q-0.759 0-1.692 0.202-0.933 0.192-1.927 0.565h-0.102v-1.926q0.564-0.151 1.63-0.333t2.102-0.181q1.21 0 2.102 0.202 0.902 0.192 1.558 0.665 0.646 0.464 0.984 1.2t0.338 1.825zM194.867 70.35v-3.135q-0.882 0.050-2.081 0.151-1.189 0.101-1.886 0.292-0.83 0.232-1.343 0.726-0.513 0.484-0.513 1.341 0 0.968 0.595 1.462 0.595 0.484 1.815 0.484 1.015 0 1.856-0.383 0.841-0.393 1.558-0.938z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M218.929 73.022q-0.543 0.141-1.189 0.232-0.636 0.091-1.138 0.091-1.753 0-2.665-0.928t-0.912-2.974v-5.988h-1.302v-1.593h1.302v-3.236h1.927v3.236h3.978v1.593h-3.978v5.131q0 0.887 0.041 1.391 0.041 0.494 0.287 0.927 0.226 0.403 0.615 0.595 0.4 0.181 1.21 0.181 0.472 0 0.984-0.131 0.513-0.141 0.738-0.232h0.102z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M232.636 67.497q0 2.752-1.435 4.345t-3.844 1.593q-2.43 0-3.865-1.593-1.425-1.593-1.425-4.345t1.425-4.345q1.435-1.603 3.865-1.603 2.409 0 3.844 1.603 1.435 1.593 1.435 4.345zM230.647 67.497q0-2.188-0.871-3.246-0.871-1.069-2.419-1.069-1.569 0-2.44 1.069-0.861 1.059-0.861 3.246 0 2.117 0.871 3.216 0.871 1.089 2.43 1.089 1.538 0 2.409-1.079 0.882-1.089 0.882-3.226z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M250.167 59.977h-2.173v-1.966h2.173zM250.044 73.123h-1.927v-11.261h1.927z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M265.063 73.123h-1.927v-6.412q0-0.776-0.092-1.452-0.092-0.686-0.338-1.069-0.256-0.423-0.738-0.625-0.482-0.212-1.251-0.212-0.789 0-1.651 0.383t-1.651 0.978v8.408h-1.927v-11.261h1.927v1.25q0.902-0.736 1.866-1.149t1.979-0.413q1.856 0 2.83 1.099t0.974 3.166z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M278.565 69.877q0 1.542-1.302 2.53-1.292 0.988-3.537 0.988-1.271 0-2.337-0.292-1.056-0.302-1.774-0.655v-2.127h0.103q0.912 0.675 2.030 1.079 1.117 0.393 2.143 0.393 1.271 0 1.989-0.403t0.718-1.27q0-0.665-0.39-1.008t-1.497-0.585q-0.41-0.091-1.076-0.212-0.656-0.121-1.199-0.262-1.507-0.393-2.143-1.149-0.625-0.766-0.625-1.875 0-0.696 0.287-1.311 0.297-0.615 0.892-1.099 0.574-0.474 1.456-0.746 0.892-0.282 1.989-0.282 1.025 0 2.071 0.252 1.056 0.242 1.753 0.595v2.026h-0.102q-0.738-0.534-1.794-0.897-1.056-0.373-2.071-0.373-1.056 0-1.784 0.403-0.728 0.393-0.728 1.18 0 0.696 0.441 1.048 0.431 0.353 1.394 0.575 0.533 0.121 1.189 0.242 0.666 0.121 1.107 0.222 1.343 0.302 2.071 1.038 0.728 0.746 0.728 1.976z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M285.014 59.977h-2.173v-1.966h2.173zM284.891 73.123h-1.927v-11.261h1.927z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M299.623 71.843q0 2.863-1.322 4.204t-4.070 1.341q-0.912 0-1.784-0.131-0.861-0.121-1.702-0.353v-1.936h0.103q0.472 0.181 1.497 0.444 1.025 0.272 2.050 0.272 0.984 0 1.63-0.232t1.005-0.645q0.359-0.393 0.513-0.948t0.154-1.24v-1.028q-0.871 0.686-1.671 1.028-0.789 0.333-2.020 0.333-2.050 0-3.26-1.452-1.199-1.462-1.199-4.113 0-1.452 0.41-2.5 0.42-1.059 1.138-1.825 0.666-0.716 1.62-1.109 0.953-0.403 1.897-0.403 0.994 0 1.661 0.202 0.677 0.192 1.425 0.595l0.123-0.484h1.804zM297.695 70.028v-6.14q-0.769-0.343-1.435-0.484-0.656-0.151-1.312-0.151-1.589 0-2.501 1.048t-0.912 3.045q0 1.895 0.677 2.873t2.245 0.978q0.841 0 1.681-0.313 0.851-0.323 1.558-0.857z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M314.601 73.123h-1.927v-6.412q0-0.776-0.092-1.452-0.092-0.686-0.338-1.069-0.256-0.423-0.738-0.625-0.482-0.212-1.251-0.212-0.789 0-1.651 0.383t-1.651 0.978v8.408h-1.927v-15.687h1.927v5.676q0.902-0.736 1.866-1.149t1.979-0.413q1.856 0 2.83 1.099t0.974 3.166z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M325.868 73.022q-0.543 0.141-1.189 0.232-0.636 0.091-1.138 0.091-1.753 0-2.665-0.928t-0.912-2.974v-5.988h-1.302v-1.593h1.302v-3.236h1.927v3.236h3.978v1.593h-3.978v5.131q0 0.887 0.041 1.391 0.041 0.494 0.287 0.927 0.226 0.403 0.615 0.595 0.4 0.181 1.21 0.181 0.472 0 0.984-0.131 0.513-0.141 0.738-0.232h0.103z"
            ></path>
            <path
                fill="#7a7a78"
                stroke="#7a7a78"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="4"
                strokeWidth="0.4627"
                d="M337.996 69.877q0 1.542-1.302 2.53-1.292 0.988-3.537 0.988-1.271 0-2.337-0.292-1.056-0.302-1.774-0.655v-2.127h0.102q0.912 0.675 2.030 1.079 1.117 0.393 2.143 0.393 1.271 0 1.989-0.403t0.718-1.27q0-0.665-0.39-1.008t-1.497-0.585q-0.41-0.091-1.076-0.212-0.656-0.121-1.199-0.262-1.507-0.393-2.143-1.149-0.625-0.766-0.625-1.875 0-0.696 0.287-1.311 0.297-0.615 0.892-1.099 0.574-0.474 1.456-0.746 0.892-0.282 1.989-0.282 1.025 0 2.071 0.252 1.056 0.242 1.753 0.595v2.026h-0.103q-0.738-0.534-1.794-0.897-1.056-0.373-2.071-0.373-1.056 0-1.784 0.403-0.728 0.393-0.728 1.18 0 0.696 0.441 1.048 0.431 0.353 1.394 0.575 0.533 0.121 1.189 0.242 0.666 0.121 1.107 0.222 1.343 0.302 2.071 1.038 0.728 0.746 0.728 1.976z"
            ></path>
            <path
                fill="#913338"
                d="M78.5 30.476q0 4.248-1.74 7.701-1.721 3.453-4.595 5.357-1.995 1.318-4.458 1.904-2.444 0.586-6.453 0.586h-7.353v-31.159h7.274q4.263 0 6.766 0.67 2.522 0.649 4.263 1.8 2.972 1.988 4.634 5.294t1.662 7.847zM74.452 30.414q0-3.662-1.193-6.173t-3.559-3.955q-1.721-1.046-3.657-1.444-1.936-0.419-4.634-0.419h-3.637v24.044h3.637q2.796 0 4.869-0.439 2.092-0.439 3.833-1.632 2.171-1.486 3.246-3.913 1.095-2.427 1.095-6.069z"
            ></path>
            <path
                fill="#913338"
                d="M101.476 46.025h-3.657v-2.49q-0.489 0.356-1.33 1.004-0.821 0.628-1.603 1.004-0.919 0.481-2.112 0.795-1.193 0.335-2.796 0.335-2.953 0-5.006-2.093t-2.053-5.336q0-2.658 1.056-4.29 1.075-1.653 3.050-2.595 1.995-0.942 4.791-1.276t6.003-0.502v-0.607q0-1.339-0.45-2.218-0.43-0.879-1.251-1.381-0.782-0.481-1.877-0.649t-2.288-0.167q-1.447 0-3.226 0.419-1.779 0.398-3.676 1.172h-0.196v-3.997q1.075-0.314 3.109-0.691t4.009-0.377q2.307 0 4.009 0.419 1.721 0.398 2.972 1.381 1.232 0.963 1.877 2.49t0.645 3.788zM97.82 40.27v-6.508q-1.682 0.105-3.97 0.314-2.268 0.209-3.598 0.607-1.584 0.481-2.562 1.507-0.978 1.004-0.978 2.783 0 2.009 1.134 3.034 1.134 1.004 3.461 1.004 1.936 0 3.539-0.795 1.603-0.816 2.972-1.946z"
            ></path>
            <path
                fill="#913338"
                d="M119.936 45.815q-1.036 0.293-2.268 0.481-1.212 0.188-2.171 0.188-3.344 0-5.084-1.925t-1.74-6.173v-12.43h-2.483v-3.306h2.483v-6.717h3.676v6.717h7.587v3.306h-7.587v10.651q0 1.841 0.078 2.888 0.078 1.025 0.548 1.925 0.43 0.837 1.173 1.235 0.763 0.377 2.307 0.377 0.9 0 1.877-0.272 0.978-0.293 1.408-0.481h0.196z"
            ></path>
            <path
                fill="#913338"
                d="M141.368 46.025h-3.657v-2.49q-0.489 0.356-1.33 1.004-0.821 0.628-1.603 1.004-0.919 0.481-2.112 0.795-1.193 0.335-2.796 0.335-2.953 0-5.006-2.093t-2.053-5.336q0-2.658 1.056-4.29 1.075-1.653 3.050-2.595 1.995-0.942 4.791-1.276t6.003-0.502v-0.607q0-1.339-0.45-2.218-0.43-0.879-1.251-1.381-0.782-0.481-1.877-0.649t-2.288-0.167q-1.447 0-3.226 0.419-1.779 0.398-3.676 1.172h-0.196v-3.997q1.075-0.314 3.109-0.691t4.009-0.377q2.307 0 4.009 0.419 1.721 0.398 2.972 1.381 1.232 0.963 1.877 2.49t0.645 3.788zM137.711 40.27v-6.508q-1.682 0.105-3.97 0.314-2.268 0.209-3.598 0.607-1.584 0.481-2.562 1.507-0.978 1.004-0.978 2.783 0 2.009 1.134 3.034 1.134 1.004 3.461 1.004 1.936 0 3.539-0.795 1.603-0.816 2.972-1.946z"
            ></path>
            <path
                fill="#7dba00"
                d="M169.937 37.131q0 1.821-0.802 3.599-0.782 1.779-2.21 3.013-1.564 1.339-3.657 2.093-2.073 0.753-5.006 0.753-3.148 0-5.671-0.628-2.503-0.628-5.104-1.862v-5.19h0.274q2.21 1.967 5.104 3.034t5.436 1.067q3.598 0 5.593-1.444 2.014-1.444 2.014-3.85 0-2.072-0.958-3.055-0.939-0.984-2.875-1.528-1.467-0.419-3.187-0.691-1.701-0.272-3.618-0.691-3.872-0.879-5.749-2.992-1.858-2.134-1.858-5.545 0-3.913 3.090-6.403 3.090-2.511 7.841-2.511 3.070 0 5.632 0.628t4.537 1.549v4.897h-0.274q-1.662-1.507-4.38-2.49-2.699-1.004-5.534-1.004-3.109 0-5.006 1.381-1.877 1.381-1.877 3.558 0 1.946 0.939 3.055t3.305 1.695q1.251 0.293 3.559 0.711t3.911 0.858q3.246 0.921 4.889 2.783t1.643 5.211z"
            ></path>
            <path
                fill="#7dba00"
                d="M192.014 44.56q-1.838 0.942-3.5 1.465-1.643 0.523-3.5 0.523-2.366 0-4.341-0.732-1.975-0.753-3.383-2.26-1.428-1.507-2.21-3.809t-0.782-5.378q0-5.734 2.933-8.998 2.953-3.265 7.783-3.265 1.877 0 3.676 0.565 1.819 0.565 3.324 1.381v4.374h-0.196q-1.682-1.402-3.481-2.155-1.779-0.753-3.481-0.753-3.129 0-4.947 2.26-1.799 2.239-1.799 6.592 0 4.227 1.76 6.508 1.779 2.26 4.986 2.26 1.115 0 2.268-0.314t2.073-0.816q0.802-0.439 1.506-0.921 0.704-0.502 1.115-0.858h0.196z"
            ></path>
            <path
                fill="#7dba00"
                d="M215.029 34.745h-16.093q0 2.155 0.606 3.767 0.606 1.59 1.662 2.616 1.017 1.004 2.405 1.507 1.408 0.502 3.090 0.502 2.229 0 4.478-0.942 2.268-0.963 3.226-1.883h0.196v4.29q-1.858 0.837-3.794 1.402t-4.067 0.565q-5.436 0-8.487-3.139-3.050-3.16-3.050-8.956 0-5.734 2.914-9.103 2.933-3.369 7.704-3.369 4.419 0 6.805 2.762 2.405 2.762 2.405 7.847zM211.451 31.732q-0.020-3.097-1.467-4.792-1.427-1.695-4.361-1.695-2.953 0-4.713 1.862-1.74 1.862-1.975 4.625z"
            ></path>
            <path
                fill="#7dba00"
                d="M238.866 46.025h-3.676v-13.309q0-1.611-0.176-3.013-0.176-1.423-0.645-2.218-0.489-0.879-1.408-1.297-0.919-0.439-2.386-0.439-1.506 0-3.148 0.795t-3.148 2.030v17.453h-3.676v-23.375h3.676v2.595q1.721-1.528 3.559-2.386t3.774-0.858q3.539 0 5.397 2.281t1.858 6.571z"
            ></path>
            <path
                fill="#7dba00"
                d="M262.918 46.025h-3.657v-2.49q-0.489 0.356-1.33 1.004-0.821 0.628-1.603 1.004-0.919 0.481-2.112 0.795-1.193 0.335-2.796 0.335-2.953 0-5.006-2.093t-2.053-5.336q0-2.658 1.056-4.29 1.075-1.653 3.050-2.595 1.995-0.942 4.791-1.276t6.003-0.502v-0.607q0-1.339-0.45-2.218-0.43-0.879-1.251-1.381-0.782-0.481-1.877-0.649t-2.288-0.167q-1.447 0-3.226 0.419-1.779 0.398-3.676 1.172h-0.196v-3.997q1.075-0.314 3.109-0.691t4.009-0.377q2.307 0 4.009 0.419 1.721 0.398 2.972 1.381 1.232 0.963 1.877 2.49t0.645 3.788zM259.262 40.27v-6.508q-1.682 0.105-3.97 0.314-2.268 0.209-3.598 0.607-1.584 0.481-2.562 1.507-0.978 1.004-0.978 2.783 0 2.009 1.134 3.034 1.134 1.004 3.461 1.004 1.936 0 3.539-0.795 1.603-0.816 2.972-1.946z"
            ></path>
            <path
                fill="#7dba00"
                d="M283.666 26.94h-0.196q-0.821-0.209-1.603-0.293-0.763-0.105-1.819-0.105-1.701 0-3.285 0.816-1.584 0.795-3.050 2.072v16.595h-3.676v-23.375h3.676v3.453q2.19-1.883 3.852-2.658 1.682-0.795 3.422-0.795 0.958 0 1.388 0.063 0.43 0.042 1.291 0.188z"
            ></path>
            <path fill="#0085a1" d="M291.116 18.737h-4.146v-4.081h4.146zM290.881 46.024h-3.676v-23.375h3.676z"></path>
            <path
                fill="#7dba00"
                d="M316.81 34.348q0 5.713-2.738 9.019t-7.333 3.306q-4.634 0-7.372-3.306-2.718-3.306-2.718-9.019t2.718-9.019q2.738-3.327 7.372-3.327 4.595 0 7.333 3.327 2.738 3.306 2.738 9.019zM313.017 34.348q0-4.541-1.662-6.738-1.662-2.218-4.615-2.218-2.992 0-4.654 2.218-1.643 2.197-1.643 6.738 0 4.395 1.662 6.675 1.662 2.26 4.634 2.26 2.933 0 4.595-2.239 1.682-2.26 1.682-6.696z"
            ></path>
            <path
                fill="#7dba00"
                d="M338.125 39.286q0 3.202-2.483 5.253-2.464 2.051-6.746 2.051-2.425 0-4.458-0.607-2.014-0.628-3.383-1.36v-4.415h0.196q1.74 1.402 3.872 2.239 2.131 0.816 4.087 0.816 2.425 0 3.794-0.837t1.369-2.637q0-1.381-0.743-2.093t-2.855-1.214q-0.782-0.188-2.053-0.439-1.251-0.251-2.288-0.544-2.875-0.816-4.087-2.386-1.193-1.59-1.193-3.892 0-1.444 0.548-2.72 0.567-1.276 1.701-2.281 1.095-0.984 2.777-1.549 1.701-0.586 3.794-0.586 1.955 0 3.95 0.523 2.014 0.502 3.344 1.235v4.206h-0.196q-1.408-1.109-3.422-1.862-2.014-0.774-3.95-0.774-2.014 0-3.402 0.837-1.388 0.816-1.388 2.448 0 1.444 0.841 2.176 0.821 0.732 2.659 1.193 1.017 0.251 2.268 0.502 1.271 0.251 2.112 0.46 2.562 0.628 3.95 2.155 1.388 1.549 1.388 4.102z"
            ></path>
            <path
                fill="#913338"
                d="M78.5 30.477q0 4.248-1.74 7.701-1.721 3.453-4.595 5.357-1.995 1.318-4.458 1.904-2.444 0.586-6.453 0.586h-7.352v-31.159h7.274q4.263 0 6.766 0.67 2.523 0.649 4.263 1.8 2.972 1.988 4.634 5.294t1.662 7.847zM74.452 30.414q0-3.662-1.193-6.173t-3.559-3.955q-1.721-1.046-3.657-1.444-1.936-0.419-4.634-0.419h-3.637v24.044h3.637q2.796 0 4.869-0.439 2.092-0.439 3.833-1.632 2.171-1.486 3.246-3.913 1.095-2.427 1.095-6.069z"
            ></path>
            <path
                fill="#913338"
                d="M101.477 46.025h-3.657v-2.49q-0.489 0.356-1.33 1.004-0.821 0.628-1.603 1.004-0.919 0.481-2.112 0.795-1.193 0.335-2.796 0.335-2.953 0-5.006-2.093t-2.053-5.336q0-2.658 1.056-4.29 1.075-1.653 3.050-2.595 1.995-0.942 4.791-1.276t6.003-0.502v-0.607q0-1.339-0.45-2.218-0.43-0.879-1.251-1.381-0.782-0.481-1.877-0.649t-2.288-0.167q-1.447 0-3.226 0.419-1.779 0.398-3.676 1.172h-0.196v-3.997q1.075-0.314 3.109-0.691t4.009-0.377q2.307 0 4.009 0.419 1.721 0.398 2.972 1.381 1.232 0.963 1.877 2.49t0.645 3.788zM97.82 40.27v-6.508q-1.682 0.105-3.97 0.314-2.268 0.209-3.598 0.607-1.584 0.481-2.562 1.507-0.978 1.004-0.978 2.783 0 2.009 1.134 3.034 1.134 1.004 3.461 1.004 1.936 0 3.539-0.795 1.603-0.816 2.972-1.946z"
            ></path>
            <path
                fill="#913338"
                d="M119.936 45.816q-1.036 0.293-2.268 0.481-1.212 0.188-2.171 0.188-3.344 0-5.084-1.925t-1.74-6.173v-12.43h-2.483v-3.306h2.483v-6.717h3.676v6.717h7.587v3.306h-7.587v10.652q0 1.841 0.078 2.888 0.078 1.025 0.547 1.925 0.43 0.837 1.173 1.235 0.763 0.377 2.307 0.377 0.9 0 1.877-0.272 0.978-0.293 1.408-0.481h0.196z"
            ></path>
            <path
                fill="#913338"
                d="M141.368 46.025h-3.657v-2.49q-0.489 0.356-1.33 1.004-0.821 0.628-1.603 1.004-0.919 0.481-2.112 0.795-1.193 0.335-2.796 0.335-2.953 0-5.006-2.093t-2.053-5.336q0-2.658 1.056-4.29 1.075-1.653 3.050-2.595 1.995-0.942 4.791-1.276t6.003-0.502v-0.607q0-1.339-0.45-2.218-0.43-0.879-1.251-1.381-0.782-0.481-1.877-0.649t-2.288-0.167q-1.447 0-3.226 0.419-1.779 0.398-3.676 1.172h-0.196v-3.997q1.075-0.314 3.109-0.691t4.009-0.377q2.307 0 4.009 0.419 1.721 0.398 2.972 1.381 1.232 0.963 1.877 2.49t0.645 3.788zM137.711 40.27v-6.508q-1.682 0.105-3.97 0.314-2.268 0.209-3.598 0.607-1.584 0.481-2.562 1.507-0.978 1.004-0.978 2.783 0 2.009 1.134 3.034 1.134 1.004 3.461 1.004 1.936 0 3.539-0.795 1.603-0.816 2.972-1.946z"
            ></path>
            <path
                fill="#7dba00"
                d="M169.937 37.131q0 1.821-0.802 3.599-0.782 1.779-2.21 3.013-1.564 1.339-3.657 2.093-2.073 0.753-5.006 0.753-3.148 0-5.671-0.628-2.503-0.628-5.104-1.862v-5.19h0.274q2.21 1.967 5.104 3.034t5.436 1.067q3.598 0 5.593-1.444 2.014-1.444 2.014-3.85 0-2.072-0.958-3.055-0.939-0.984-2.875-1.528-1.467-0.419-3.187-0.691-1.701-0.272-3.618-0.691-3.872-0.879-5.749-2.992-1.858-2.134-1.858-5.545 0-3.913 3.090-6.403 3.090-2.511 7.841-2.511 3.070 0 5.632 0.628t4.537 1.549v4.897h-0.274q-1.662-1.507-4.38-2.49-2.699-1.004-5.534-1.004-3.109 0-5.006 1.381-1.877 1.381-1.877 3.558 0 1.946 0.939 3.055t3.305 1.695q1.251 0.293 3.559 0.711t3.911 0.858q3.246 0.921 4.889 2.783t1.643 5.211z"
            ></path>
            <path
                fill="#7dba00"
                d="M192.014 44.56q-1.838 0.942-3.5 1.465-1.643 0.523-3.5 0.523-2.366 0-4.341-0.732-1.975-0.753-3.383-2.26-1.428-1.507-2.21-3.809t-0.782-5.378q0-5.734 2.933-8.998 2.953-3.265 7.783-3.265 1.877 0 3.676 0.565 1.819 0.565 3.324 1.381v4.374h-0.196q-1.682-1.402-3.481-2.155-1.779-0.753-3.481-0.753-3.129 0-4.947 2.26-1.799 2.239-1.799 6.592 0 4.227 1.76 6.508 1.779 2.26 4.986 2.26 1.115 0 2.268-0.314t2.073-0.816q0.802-0.439 1.506-0.921 0.704-0.502 1.115-0.858h0.196z"
            ></path>
            <path
                fill="#7dba00"
                d="M215.029 34.746h-16.093q0 2.155 0.606 3.767 0.606 1.59 1.662 2.616 1.017 1.004 2.405 1.507 1.408 0.502 3.090 0.502 2.229 0 4.478-0.942 2.268-0.963 3.226-1.883h0.196v4.29q-1.858 0.837-3.794 1.402t-4.067 0.565q-5.436 0-8.487-3.139-3.050-3.16-3.050-8.956 0-5.734 2.914-9.103 2.933-3.369 7.704-3.369 4.419 0 6.805 2.762 2.405 2.762 2.405 7.847zM211.451 31.732q-0.020-3.097-1.467-4.792-1.427-1.695-4.361-1.695-2.953 0-4.713 1.862-1.74 1.862-1.975 4.625z"
            ></path>
            <path
                fill="#7dba00"
                d="M238.866 46.025h-3.676v-13.309q0-1.611-0.176-3.013-0.176-1.423-0.645-2.218-0.489-0.879-1.408-1.297-0.919-0.439-2.386-0.439-1.506 0-3.148 0.795t-3.148 2.030v17.453h-3.676v-23.375h3.676v2.595q1.721-1.528 3.559-2.386t3.774-0.858q3.539 0 5.397 2.281t1.858 6.571z"
            ></path>
            <path
                fill="#7dba00"
                d="M262.918 46.025h-3.657v-2.49q-0.489 0.356-1.33 1.004-0.821 0.628-1.603 1.004-0.919 0.481-2.112 0.795-1.193 0.335-2.796 0.335-2.953 0-5.006-2.093t-2.053-5.336q0-2.658 1.056-4.29 1.075-1.653 3.050-2.595 1.995-0.942 4.791-1.276t6.003-0.502v-0.607q0-1.339-0.45-2.218-0.43-0.879-1.251-1.381-0.782-0.481-1.877-0.649t-2.288-0.167q-1.447 0-3.226 0.419-1.779 0.398-3.676 1.172h-0.195v-3.997q1.075-0.314 3.109-0.691t4.009-0.377q2.307 0 4.009 0.419 1.721 0.398 2.972 1.381 1.232 0.963 1.877 2.49t0.645 3.788zM259.262 40.27v-6.508q-1.682 0.105-3.97 0.314-2.268 0.209-3.598 0.607-1.584 0.481-2.562 1.507-0.978 1.004-0.978 2.783 0 2.009 1.134 3.034 1.134 1.004 3.461 1.004 1.936 0 3.539-0.795 1.603-0.816 2.972-1.946z"
            ></path>
            <path
                fill="#7dba00"
                d="M283.666 26.94h-0.196q-0.821-0.209-1.603-0.293-0.763-0.105-1.819-0.105-1.701 0-3.285 0.816-1.584 0.795-3.050 2.072v16.595h-3.676v-23.375h3.676v3.453q2.19-1.883 3.852-2.658 1.682-0.795 3.422-0.795 0.958 0 1.388 0.063 0.43 0.042 1.291 0.188z"
            ></path>
            <path fill="#7dba00" d="M290.881 46.025h-3.676v-23.375h3.676z"></path>
            <path
                fill="#7dba00"
                d="M316.81 34.348q0 5.713-2.738 9.019t-7.333 3.306q-4.634 0-7.372-3.306-2.718-3.306-2.718-9.019t2.718-9.019q2.738-3.327 7.372-3.327 4.595 0 7.333 3.327 2.738 3.306 2.738 9.019zM313.017 34.348q0-4.541-1.662-6.738-1.662-2.218-4.615-2.218-2.992 0-4.654 2.218-1.643 2.197-1.643 6.738 0 4.395 1.662 6.675 1.662 2.26 4.634 2.26 2.933 0 4.595-2.239 1.682-2.26 1.682-6.696z"
            ></path>
            <path
                fill="#7dba00"
                d="M338.125 39.287q0 3.202-2.483 5.252-2.464 2.051-6.746 2.051-2.425 0-4.458-0.607-2.014-0.628-3.383-1.36v-4.415h0.196q1.74 1.402 3.872 2.239 2.131 0.816 4.087 0.816 2.425 0 3.794-0.837t1.369-2.637q0-1.381-0.743-2.093t-2.855-1.214q-0.782-0.188-2.053-0.439-1.251-0.251-2.288-0.544-2.875-0.816-4.087-2.386-1.193-1.59-1.193-3.892 0-1.444 0.548-2.72 0.567-1.276 1.701-2.281 1.095-0.984 2.777-1.549 1.701-0.586 3.794-0.586 1.955 0 3.95 0.523 2.014 0.502 3.344 1.235v4.206h-0.196q-1.408-1.109-3.422-1.862-2.014-0.774-3.95-0.774-2.014 0-3.402 0.837-1.388 0.816-1.388 2.448 0 1.444 0.841 2.176 0.821 0.732 2.659 1.193 1.017 0.251 2.268 0.502 1.271 0.251 2.112 0.46 2.562 0.628 3.95 2.155 1.388 1.549 1.388 4.102z"
            ></path>
        </svg>
    );
};

export default Logo;
