import React from "react";
import { navigate } from "gatsby";
import { INavLink, mergeStyleSets, ITheme, ActionButton, Nav, Stack, DefaultEffects, useTheme, } from "@fluentui/react"; // eslint-disable-line prettier/prettier

import BodyContext from "../body/BodyContext";

export interface MenuProps {
    path?: string;
    groups?: any;
    groupsCollapse?: any;
}

const LOCAL_URL = /^\/(?!\/)/;

function onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
    if (item && LOCAL_URL.test(item.url)) {
        navigate(item && item.url);
        ev && ev.preventDefault();
    }
}

function onRenderLink(group: INavLink | undefined, collapsed?: boolean): JSX.Element {
    if (collapsed) return <div />;
    if (group && group.isExpanded !== undefined) {
        return <h3>{group && group.name}</h3>;
    } else {
        return <div>{group && group.name}</div>;
    }
}

const MenuBase: React.FunctionComponent<MenuProps> = (props: MenuProps) => {
    const theme = useTheme();
    return (
        <BodyContext.Consumer>
            {(body) => {
                const { path, groups, groupsCollapse = groups } = props;
                const isCollapsed = (body && body.menuCollapsed) || false;
                const menuGroup = isCollapsed ? groupsCollapse : groups;
                const menubarStyles = mergeStyleSets({
                    menuDivRoot: {
                        width: body.menuWidthInternal,
                        height: `100%`,
                        boxShadow: DefaultEffects.elevation8,
                        backgroundColor: (theme as ITheme).palette.white,
                        transitionDelay: `0s`,
                        transitionTimingFunction: `ease`,
                        transitionDuration: `0.25s`,
                        transitionProperty: `all`,
                        selectors: {
                            "@media(max-width: 600px)": {
                                width: body.menuWidthMobile,
                            },
                        },
                    },
                    menuRoot: {
                        height: `100%`,
                        zIndex: 100,
                    },
                });
                return (
                    <Stack.Item className={menubarStyles.menuRoot}>
                        <div className={menubarStyles.menuDivRoot} id="menuPane">
                            <ActionButton
                                onClick={() => body.toggleMenu()}
                                iconProps={{ iconName: `GlobalNavButton` }}
                                title="Show or hide menu"
                            />
                            <Nav
                                onLinkClick={onLinkClick}
                                onRenderLink={(group: INavLink | undefined) => onRenderLink(group, isCollapsed)}
                                selectedKey={path}
                                theme={theme}
                                styles={{
                                    groupContent: {
                                        animationDuration: `0s`, // disable animations
                                    },
                                }}
                                expandButtonAriaLabel="Expand or collapse"
                                groups={menuGroup}
                            />
                        </div>
                    </Stack.Item>
                );
            }}
        </BodyContext.Consumer>
    );
};

export default MenuBase;
