import React, { PropsWithChildren } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { mergeStyleSets, Stack, useTheme } from "@fluentui/react";

import Header from "./Header";
import Footer from "./Footer";
import Body from "./body/Body";

import "./layout.css";
import GlobalStyle from "./GlobalStyle";

interface LayoutProps {
    noHeader?: boolean;
    noFooter?: boolean;
    isIndex?: boolean;
}

const Layout: React.FunctionComponent<LayoutProps> = (props: PropsWithChildren<LayoutProps>) => {
    const { children, isIndex, noHeader = false, noFooter = false } = props;
    const theme = useTheme();

    const layoutStyles = mergeStyleSets({
        mainStyle: {
            backgroundColor: theme.palette.white,
        },
        headerStyle: {
            height: `50px`,
            lineHeight: `50px`,
            width: `100%`,
            backgroundColor: theme.palette.themeDarkAlt,
        },
        contentStyle: {},
        footerStyle: {
            height: `30px`,
            lineHeight: `30px`,
            width: `100%`,
            bottom: `0px`,
            position: `fixed`,
            backgroundColor: theme.palette.themeDarkAlt,
        },
    });

    const data = useStaticQuery(graphql`
        query LayoutQuery {
            site {
                siteMetadata {
                    title
                    fullName
                    url
                }
            }
        }
    `);

    return (
        <>
            <GlobalStyle />
            <Stack verticalFill className={layoutStyles.mainStyle}>
                {!noHeader && (
                    <Stack.Item grow disableShrink className={layoutStyles.headerStyle}>
                        <Header siteTitle={data.site.siteMetadata.title} url={data.site.siteMetadata.url} />
                    </Stack.Item>
                )}
                <Stack.Item grow className={layoutStyles.contentStyle}>
                    <Body isIndex={isIndex}>{children}</Body>
                </Stack.Item>
                {!noFooter && (
                    <Stack.Item grow disableShrink className={layoutStyles.footerStyle}>
                        <Footer fullName={data.site.siteMetadata.fullName} url={data.site.siteMetadata.url} />
                    </Stack.Item>
                )}
            </Stack>
        </>
    );
};

export default Layout;
