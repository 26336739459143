import React, { PropsWithChildren, CSSProperties } from "react";
import { Stack } from "@fluentui/react";

interface FlagProps {
    width: number;
}

const Flag: React.FunctionComponent<FlagProps> = (props: PropsWithChildren<FlagProps>) => {
    const { width } = props;

    const svgStyle: CSSProperties = {
        height: `100%`,
    };

    const textStyle: CSSProperties = {
        lineHeight: "96%",
    };

    return (
        <Stack horizontal horizontalAlign="center">
            <Stack.Item grow>{` `}</Stack.Item>
            <Stack.Item>
                <i>
                    <small style={textStyle}>Lovingly made in&nbsp;</small>
                </i>
            </Stack.Item>
            <Stack.Item>
                <svg width={width} viewBox="0 0 91 60" fill="none" style={svgStyle} aria-label="Flag of the Achterhoek">
                    <title>{"Lovingly made in 'de Achterhoek'"}</title>
                    <g clipPath="url(#clip0)">
                        <path
                            d="M45.26 21C33.544 16.443 13.275-.6 13.275-.6h63.97S56.977 16.443 45.26 21zM2.614-.6S29.64 21.887 45.261 27.9C60.884 21.887 87.906-.6 87.906-.6H2.614zM91.168 2.364v7.548C84.95 15.75 73.838 24.11 63.516 29.999c10.64 6.071 20.923 14.704 27.652 20.09v7.547c-7.532-6.129-25.442-20.238-39.471-27.637 14.027-7.4 31.939-21.507 39.47-27.635z"
                            fill="#1A5B1C"
                        />
                        <path
                            d="M91.168 50.09C84.438 44.702 74.156 36.07 63.516 30 73.838 24.11 84.951 15.75 91.168 9.911v40.177z"
                            fill="#0FAB4B"
                        />
                        <path
                            d="M77.245 60.6S56.977 43.557 45.26 39c-11.717 4.557-31.985 21.6-31.985 21.6h63.97z"
                            fill="#6EC72E"
                        />
                        <path
                            d="M45.26 32.1c15.617 6.011 42.635 28.49 42.646 28.5h6.854S69.942 39.627 51.696 30C69.945 20.377 94.76-.6 94.76-.6h-6.854c-.01.009-27.025 22.488-42.645 28.5C29.642 21.888 2.625-.591 2.614-.6H-4.24s24.818 20.973 43.064 30.599c-18.248 9.624-43.064 30.6-43.064 30.6h6.854c.01-.008 27.025-22.488 42.645-28.5z"
                            fill="#F1E8C1"
                        />
                        <path
                            d="M13.275 60.6S33.543 43.557 45.26 39c11.716 4.557 31.985 21.6 31.985 21.6h-63.97zm74.63 0S60.885 38.113 45.262 32.1C29.639 38.113 2.614 60.6 2.614 60.6h85.292z"
                            fill="#1A5B1C"
                        />
                        <path
                            d="M77.245-.6S56.977 16.443 45.26 21C33.543 16.443 13.275-.6 13.275-.6h63.97z"
                            fill="#6EC72E"
                        />
                        <path
                            d="M-.648 2.364v7.548C5.57 15.75 16.682 24.11 27.004 29.999 16.364 36.07 6.08 44.703-.648 50.089v7.547c7.532-6.129 25.442-20.238 39.472-27.637C24.796 22.6 6.884 8.492-.648 2.364z"
                            fill="#1A5B1C"
                        />
                        <path
                            d="M-.648 50.09C6.082 44.702 16.364 36.07 27.004 30 16.682 24.11 5.569 15.75-.648 9.911v40.177z"
                            fill="#0FAB4B"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <path fill="#fff" d="M0.26001 0.0000172332H90.26001V60.0000172332H0.26001z" />
                        </clipPath>
                    </defs>
                </svg>
            </Stack.Item>
            <Stack.Item grow>{` `}</Stack.Item>
        </Stack>
    );
};

export default Flag;
