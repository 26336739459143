import React from "react";
import { Stack, IStackTokens, IStackStyles, IStackItemStyles, useTheme } from "@fluentui/react";

import LinkPrimaryBackground from "./LinkPrimaryBackground";
import Flag from "./Flag";

interface FooterProps {
    fullName?: string;
    url?: string;
}

const Footer: React.FunctionComponent<FooterProps> = (props: FooterProps) => {
    const { fullName = ``, url = `` } = props;
    const theme = useTheme();

    const footerStyle: IStackStyles = {
        root: {
            color: theme.palette.white,
            background: theme.palette.themeDark,
            height: `100%`,
        },
    };

    const itemStyle: IStackItemStyles = {
        root: {
            overflow: `hidden`,
        },
    };

    const footerTokens: IStackTokens = {
        childrenGap: 10,
        padding: `0px 5px 0px 5px`,
    };
    return (
        <Stack horizontal styles={footerStyle} tokens={footerTokens}>
            <Stack.Item disableShrink>
                © {new Date().getFullYear()}
                {` `}
                <LinkPrimaryBackground href={url}>{fullName}</LinkPrimaryBackground>
            </Stack.Item>
            <Stack.Item grow={100} styles={itemStyle}>{` `}</Stack.Item>
            <Stack.Item disableShrink>
                <Flag width={27} />
            </Stack.Item>
        </Stack>
    );
};

export default Footer;
