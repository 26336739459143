import React from "react";
import {
    mergeStyleSets,
    IStackTokens,
    Stack,
    ActionButton,
    IButtonStyles,
    Persona,
    PersonaSize,
    IPersonaStyles,
    useTheme,
    IStyle,
} from "@fluentui/react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import Logo from "./logo/Logo";
import LinkPrimaryBackground from "./LinkPrimaryBackground";
import { loginRequest } from "../utils/msalConfig";

interface HeaderProps {
    siteTitle?: string;
    url?: string;
}

const Header: React.FunctionComponent<HeaderProps> = (props: HeaderProps) => {
    const { siteTitle = ``, url = `` } = props;
    const theme = useTheme();
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const headerStyles = mergeStyleSets({
        headerRoot: {
            color: theme.palette.white,
            background: theme.palette.themeDark,
            height: `100%`,
        },
        titleRoot: {
            fontSize: theme.fonts.xLarge,
            margin: `0px`,
        },
        titleItemRoot: {
            overflow: `hidden`,
        },
    });

    const loginStyle: IButtonStyles = {
        root: {
            height: `100%`,
            right: `10px`,
        },
        icon: {
            color: theme.palette.white,
        },
        label: {
            color: theme.palette.white,
        },
        iconHovered: {
            color: theme.palette.neutralTertiary,
        },
        labelHovered: {
            color: theme.palette.neutralTertiary,
        },
    };

    const personaColorStyle: IStyle = {
        color: theme.palette.white,
        selectors: {
            ":hover": {
                color: theme.palette.neutralTertiary,
                cursor: `pointer`,
            },
        },
    };

    const personaStyle: IPersonaStyles = {
        root: {
            height: `100%`,
            right: `10px`,
            selectors: {
                ":hover": {
                    color: theme.palette.neutralTertiary,
                    cursor: `pointer`,
                },
                "&:hover [class*='primaryText-']": {
                    color: theme.palette.neutralTertiary,
                },
            },
        },
        details: personaColorStyle,
        optionalText: personaColorStyle,
        primaryText: personaColorStyle,
        secondaryText: personaColorStyle,
        tertiaryText: personaColorStyle,
        textContent: personaColorStyle,
    };

    const headerTokens: IStackTokens = {
        childrenGap: `10`,
        padding: `0px 0px 0px 5px`,
    };

    const login = () => {
        instance.acquireTokenSilent(loginRequest).catch((e) => {
            instance.loginRedirect(loginRequest).catch((e) => {
                console.error(e);
            });
        });
    };

    const logout = () => {
        instance.logoutRedirect().catch((e) => {
            console.error(e);
        });
    };

    const loginButton = (
        <ActionButton
            styles={loginStyle}
            iconProps={{ iconName: "AddFriend" }}
            allowDisabledFocus={true}
            onClick={login}
        >
            Sign In
        </ActionButton>
    );
    const personaIcon = (
        <Persona
            styles={personaStyle}
            text={isAuthenticated ? accounts[0] && accounts[0].name : ""}
            hidePersonaDetails
            size={PersonaSize.size32}
            onClick={logout}
        />
    );

    return (
        <Stack horizontal className={headerStyles.headerRoot} tokens={headerTokens}>
            <Stack.Item disableShrink>
                <LinkPrimaryBackground href={url}>
                    <Logo height={48} width={48} />
                </LinkPrimaryBackground>
            </Stack.Item>
            <Stack.Item grow={100} className={headerStyles.titleItemRoot}>
                <h1 className={headerStyles.titleRoot}>
                    <LinkPrimaryBackground href="/">{siteTitle}</LinkPrimaryBackground>
                </h1>
            </Stack.Item>
            <Stack.Item disableShrink>{isAuthenticated ? personaIcon : loginButton}</Stack.Item>
        </Stack>
    );
};

export default Header;
