/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";

const BodyDimensionsDefaultState = {
    menuCollapsed: false,
    menuWidth: 200,
    menuWidthMobile: 40,
    menuWidthInternal: 200,
    menuWidthExternal: 220,
    toggleMenu: () => {},
    collapseMenu: () => {},
    expandMenu: () => {},
};

const BodyContext = React.createContext(BodyDimensionsDefaultState);

export default BodyContext;
